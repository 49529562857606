// prettier-ignore
export default {
  pt: {
    hints: {
      interval: 'Filtre os resultados por um determinado intervalo',
      connector: 'Filtre por um conector específico',
      data: 'Filtre por um dado específico',
      event: 'Filtre por um determinado tipo de evento',
      download: 'Baixar os resultados',
      reset_filters: 'Redefinir filtros'
    }
  },
  en: {
    hints: {
      interval: 'Filter the results by a certain interval',
      connector: 'Filter by a specific connector',
      data: 'Filter by a specific data',
      event: 'Filter by a certain type of event',
      download: 'Download results',
      reset_filters: 'Reset filters'
    }
  }
}
